import axios from 'axios'
import { TenantDto, TenantsClient } from 'ritem-common'

let currentTenant: TenantDto

// Use correct API endpoint based on hostname
// Hardcoded for localhost and 127.0.0.1
// Relative (empty) for production (/api/<endpoint> is always appended in backend client)
const API_ENDPOINT =
  process.env.NODE_ENV === 'staging' ? 'https://ritemanwaltasaservice.staging.cloudnuggets.io/api' :
    window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
      ? 'https://localhost:44322'
      : '/api'

const PREVIEW_DEFAULT_SCALE = 0.5
const PREVIEW_DEFAULT_HEIGHT = 660

const getEnvironmentName = async (short: boolean): Promise<string> => {
  if (!currentTenant) {
    currentTenant = await new TenantsClient(API_ENDPOINT, axios).getCurrentTenant()
  }
  return (short ? currentTenant.shortName : currentTenant.name);
  }

export { API_ENDPOINT, PREVIEW_DEFAULT_SCALE, PREVIEW_DEFAULT_HEIGHT, getEnvironmentName }
