import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { container } from 'inversify-props'
import { InterviewsClient, CaseInterviewSharesClient, TenantsClient } from 'ritem-common'
import { API_ENDPOINT } from './services/environment'
import axios from 'axios'
import 'reflect-metadata'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false

container.addSingleton(
  () => new InterviewsClient(API_ENDPOINT, axios),
  'InterviewsClient'
)

container.addSingleton(
  () => new CaseInterviewSharesClient(API_ENDPOINT, axios),
  'CaseInterviewSharesClient'
)

container.addSingleton(
  () => new TenantsClient(API_ENDPOINT, axios),
  'TenantsClient'
)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
