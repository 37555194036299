import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import { getEnvironmentName } from '@/services/environment'

Vue.use(Router)

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
    redirect: 'onboarding',
    children: [
      {
        path: 'onboarding',
        name: 'onboarding',
        component: () =>
          import(
            /* webpackChunkName: "onboarding" */ './views/onboarding/OnboardingOverview.vue'
          )
      },
      {
        path: 'onboarding/:id',
        name: 'onboarding-detail',
        component: () =>
          import(
            /* webpackChunkName: "onboarding-detail" */ './views/onboarding/OnboardingDetail.vue'
          )
      },
      {
        path: 'share/:id',
        name: 'share-detail',
        component: () =>
          import(
            /* webpackChunkName: "share-detail" */ './views/share/SharedInterview.vue'
          )
      }
    ]
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: () =>
      import(/* webpackChunkName: "imprint" */ './views/Imprint.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  let envName =  await getEnvironmentName(true)
  document.title = 'alice Interview' + (envName != '' ? ' (' : '') + envName + (envName != '' ? ')' : '')
  next()
})

export default router
