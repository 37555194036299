<template>
  <div>
    <div v-if="false" class="datable-actionbar">
      <div class="actionbar">
        <div class="actionbar__block-left">
          <p>&nbsp;</p>
          <!-- <b-button variant="outline-ritem-secondary" size="sm" class="btn-icon"
            ><i class="fas fa-check"></i>Erledigt</b-button
          >
          <b-button
            disabled
            variant="outline-ritem-secondary"
            size="sm"
            class="btn-icon"
            ><i class="fas fa-trash"></i>Löschen</b-button
          > -->
        </div>
        <div class="actionbar__block-right">
          <div class="actionbar__block-spacer-left"></div>
        </div>
      </div>
    </div>

    <!-- <div class="datable-filterbar">
      <div class="card">
        <div class="filterbar">
          <div class="filterbar__title">Filter</div>
          <div class="filterbar__block">
            <div class="filterbar__block-ele">
              <b-form-input
                v-model="text"
                size="sm"
                placeholder="Benutzer"
              ></b-form-input>
            </div>
            <div class="filterbar__block-ele">
              <b-form-input
                v-model="text"
                size="sm"
                placeholder="Test"
              ></b-form-input>
            </div>
          </div>
          <div class="filterbar__block">
            <div class="filterbar__block-ele">
              <b-form-select size="sm">
                <b-form-select-option value="null"
                  >Please select an option</b-form-select-option
                >
                <b-form-select-option value="a">Option A</b-form-select-option>
                <b-form-select-option value="b">Option B</b-form-select-option>
                <b-form-select-option value="b">Option C</b-form-select-option>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="datable-table">
      <div class="card">
        <b-table
          ref="table"
          class="table-hover"
          @row-clicked="rowClicked"
          @row-dblclicked="rowDblclicked"
          @row-selected="rowSelected"
          :striped="striped"
          small
          :hover="hover"
          :items="itemsFunction ? getItems : items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :busy.sync="isBusy"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :sort-compare="sortCompare"
          @sort-changed="sortChanged"
          no-provider-sorting
          no-provider-filtering
          :no-local-sorting="!localSorting"
          :tbody-tr-class="rowClassFunction ? rowClassFunction : null"
          :filter-function="filterFunction"
          :filter="filter"
          :selectable="selectable"
          :no-sort-reset="noSortReset"
          select-mode="single"
        >
          <template
            v-for="slotName in Object.keys($scopedSlots)"
            v-slot:[slotName]="slotScope"
          >
            <slot :name="slotName" v-bind="slotScope"></slot>
          </template>
        </b-table>
      </div>

      <b-pagination
        :disabled="!paginationEnabled"
        v-if="isPaged && totalCount > perPage"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalCount"
        size="sm"
        class="mt-2"
      ></b-pagination>
    </div>
  </div>
</template>

<script lang="ts">
import { BvTableCtxObject, BTable } from 'bootstrap-vue'
import { mixins } from 'vue-class-component'
import { Component, Emit, Prop, PropSync, Ref, Vue } from 'vue-property-decorator'

@Component({
  components: {
    BTable
  }
})
export default class List extends Vue {
  @Prop({ default: 20 })
  perPage: number

  @Prop()
  fields: any[]

  @Prop({ default: true })
  striped: boolean

  @Prop({ default: true })
  localSorting: boolean

  @Prop({ default: true })
  hover: boolean

  @Prop({ default: true })
  isPaged: boolean

  @Prop({ default: true })
  paginationEnabled: boolean

  @Prop({ default: false })
  selectable: boolean

  @Prop({ default: false })
  generateFilterRow: boolean

  @PropSync('filter')
  syncedFilter!: any

  @Prop()
  items: any[]

  @Prop()
  sortBy: string

  @Prop()
  sortDesc: boolean

  @Prop({ default: false })
  noSortReset: boolean

  @Prop()
  rowClassFunction: (item: any, type: any) => string

  @Prop()
  itemsFunction?: (
    currentPage: number,
    perPage: number
  ) => Promise<{ totalCount: number; data: any[] }>

  @Prop()
  filterFunction: (item: any, filter: any) => boolean

  @Prop()
  sortCompare: (
    aRow: any,
    bRow: any,
    key: any,
    sortDesc: any,
    formatter: any,
    compareOptions: any,
    compareLocale: any
  ) => any

  @Prop()
  filter: any

  @Prop()
  topItem: any

  @Ref('table')
  table: any

  currentPage: number = 1
  totalCount: number = 0
  isBusy: boolean = false

  @Emit()
  rowClicked(item: any) {
    // lint-placeholder
  }

  @Emit()
  rowDblclicked(item: any) {
    // lint-placeholder
  }

  @Emit()
  rowSelected(item: any) {
    // lint-placeholder
  }

  @Emit()
  itemsChanged(items: any[]) {
    return items
  }

  @Emit()
  sortChanged(ctx: any) {
    // lint-placeholder
  }

  refresh() {
    this.table.refresh()
  }

  resetPaging() {
    this.currentPage = 1
  }

  async getItems(ctx: BvTableCtxObject) {
    this.isBusy = true

    let items: any[] = []
    if (this.itemsFunction) {
      const pagedData = await this.itemsFunction(
        this.currentPage - 1,
        this.perPage
      )
      this.totalCount = pagedData.totalCount
      items = pagedData.data
    } else {
      items = [...this.items]
    }

    this.itemsChanged(items)

    this.isBusy = false

    if (this.topItem) {
      items.unshift(this.topItem)
    }

    return items
  }
}
</script>

<style lang="scss">

</style>
