







import { Component, Vue } from 'vue-property-decorator'
import { inject } from 'inversify-props'
import { getEnvironmentName } from '@/services/environment'

@Component({ components: {} })
export default class App extends Vue {
  environmentName: string = ''


  get envName() {
    return (this.environmentName != '' ? ' -&nbsp;&nbsp;' : '') + this.environmentName
  }

  async created() {
    this.environmentName = await getEnvironmentName(false)
    console.log('created app')
  }
}
