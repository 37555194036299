<template>
  <div id="interview" style="display: inline-block; width: 100%"></div>
</template>

<script lang="ts">
import {
defaultBootstrapCss, Question, StylesManager, Survey
} from 'survey-knockout'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({ components: {} })
export default class Interview extends Vue {
  @Prop({ default: '' })
  interviewData: string
  localInterviewData: string

  private currentSurvey: Survey

  /**
   * Function is called just before rendering. Allows to adjust StylesManager/general style settings.
   */
  @Prop()
  applyCssClasses: (defaultBootstrapCss: any) => void

  @Prop()
  locale: string

  @Prop({ default: false })
  verbose: boolean

  @Prop({ default: null })
  partialResult: string

  @Emit()
  completed(result: any) {}

  @Emit()
  valueChanged(result: any) {}

  created() {
    StylesManager.applyTheme('bootstrap')
  }

  mounted() {
    this.renderSurvey()
  }

  updated() {
    this.renderSurvey()
  }

  @Watch('interviewData')
  onDataChange() {
    this.renderSurvey()
  }

  hasErrors(): boolean {
    return this.currentSurvey.hasErrors()
  }

  private renderSurvey() {
    if (this.interviewData.length === 0) {
      return
    }
    if (this.applyCssClasses) {
      this.applyCssClasses(defaultBootstrapCss)
    }

    this.localInterviewData = ''.concat(this.interviewData)
    const survey = new Survey(this.localInterviewData)
    this.currentSurvey = survey

    if (this.locale) {
      survey.locale = this.locale
    }

    if (this.partialResult) {
      survey.data = this.partialResult
    }

    survey.onComplete.add((sender: any, options: any) => {
      const data = this.fillWithEmptyValues(
        sender.data,
        sender.getAllQuestions()
      )
      this.completed(data)
    })

    survey.onValueChanged.add((sender: any, options: any) => {
      const data = this.fillWithEmptyValues(
        sender.data,
        sender.getAllQuestions()
      )
      this.valueChanged(data)
    })

    survey.render('interview')
  }

  private fillWithEmptyValues(data: any, questions: Question[]) {
    //console.log('Raw-Data: ' + JSON.stringify(data))
    for (const question of questions) {
      if (!data[question.name]) {
        if (question.value != undefined && question.value != null) {
          if (question.value === '') {
            // Replace all Questions with empty string as a result with null
            data[question.name] = null
          } else {
            // there are implementation errors in surveyjs which miss set values from defualtValue and do not include them in the result
            data[question.name] = question.value
          }
        } else {
          if (question.getType() == 'boolean') {
            data[question.name] = false
          } else {
            data[question.name] = null
          }
        }
      } else {
        if (question.getType() == 'boolean' && question.value == null) {
          data[question.name] = false
        }
      }
    }
    //console.log('Chg-Data: ' + JSON.stringify(data))
    return data
  }
}
</script>

<style lang="scss">
</style>